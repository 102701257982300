<template>
  <v-form @submit.stop.prevent="checkForm()" ref="form" class="mt-5">
    <v-text-field
      class="mt-2"
      :label="$t('name')"
      :rules="[required]"
      v-model="thesis.Name"
      data-test="Thesis:Basic:Name"
    />
    <v-textarea
      v-if="thesis.Overview"
      class="mt-2"
      :label="$t('overall')"
      auto-grow
      outlined
      rows="5"
      :rules="[required]"
      v-model="thesis.Overview[$i18n.locale]"
      data-test="Thesis:Basic:Overview"
    />
    <v-text-field
      data-test="Thesis:Basic:Sector"
      class="mt-2"
      :label="$t('sector')"
      v-model="thesis.Sector"
    />
    <v-text-field
      data-test="Thesis:Basic:Website"
      class="mt-2"
      :label="$t('site')"
      v-model="thesis.Website"
    />
    <div class="mt-2">
      <span>{{ $t("thesis_showcased") }}</span>
      <v-radio-group center v-model="thesis.Showcased" row>
        <v-radio
          data-test="Thesis:Basic:ShowPipelineYes"
          :label="$t('yes')"
          :value="true"
        ></v-radio>
        <v-radio
          data-test="Thesis:Basic:ShowPipelineNo"
          :label="$t('no')"
          :value="false"
        ></v-radio>
      </v-radio-group>
    </div>
    <v-select
      class="mt-2"
      :label="$t('pipeline_index')"
      v-if="pipeline_index_options.length > 0"
      :items="pipeline_index_options"
      v-model="thesis.PipelineIndex"
      outlined
    ></v-select>
    <div rows="5" v-if="thesis.InvestmentConditionsIntro">
      <span class="introduction-text-label">{{
        $t("introduction") + ":"
      }}</span>
      <quill-editor
        data-test="Thesis:Basic:Introduction"
        ref="myQuillEditor"
        v-model="thesis.InvestmentConditionsIntro[$i18n.locale]"
      />
    </div>
    <div rows="5" v-if="thesis.InvestmentConditionsText" class="mt-10">
      <span class="condition-text-label">{{ $t("text") + ":" }}</span>
      <quill-editor
        data-test="Thesis:Basic:Text"
        ref="myQuillEditor"
        v-model="thesis.InvestmentConditionsText[$i18n.locale]"
      />
    </div>
    <v-text-field
      v-if="thesis.IntroVideo"
      class="mt-10 pt-0"
      type="number"
      :label="$t('intro_video')"
      :hint="$tc('vimeo_hint', 1)"
      v-model="thesis.IntroVideo[$i18n.locale]"
    />
    <div class="mt-2">
      <span class="mb-3 ml-1 btn-choose-img-title">{{ $t("logo") }}</span>
      <v-row align="center">
        <v-img
          class="ml-3"
          v-if="thesis.Base64Logo != null"
          :src="thesis.Base64Logo"
          max-height="5rem"
          max-width="5rem"
        >
        </v-img>
        <v-img
          class="ml-3"
          v-else-if="thesis.Logo != null"
          :src="gs.get_photo_path(thesis.Logo)"
          max-height="5rem"
          max-width="5rem"
        >
        </v-img>
        <input
          class="ml-4"
          id="imageLogoInput"
          name="imageLogoInput"
          ref="imageLogoInput"
          type="file"
          accept="image/png, image/jpeg, image/bmp"
          @change="setImageLogo"
        />
      </v-row>
    </div>
    <div class="mt-6 mb-2">
      <span class="mb-3 ml-1 btn-choose-img-title">{{ $t("banner") }}</span>
      <v-row align="center">
        <v-img
          class="ml-3"
          v-if="this.banner && this.banner[$i18n.locale]"
          :src="
            gs.get_photo_path(
              this.banner[$i18n.locale] + '?t=' + Math.random().toString(5)
            )
          "
          max-height="5rem"
          max-width="5rem"
        >
        </v-img>
        <input
          class="ml-4"
          id="imageBannerInput"
          name="imageBannerInput"
          ref="imageBannerInput"
          type="file"
          accept="image/png, image/jpeg, image/bmp"
          @change="setImageBanner"
        />
      </v-row>
    </div>

    <v-card-actions class="pl-0 dxa_modal_actions">
      <v-btn
        data-test="Thesis:Basic:BtnSaveThesis"
        class="dxa_modal_btnSuccess"
        color="primary"
        min-width="100"
        :loading="loading"
        type="submit"
        >{{ $t("save") }}</v-btn
      >
      <v-btn
        class="dxa_modal_btnError"
        color="secondary"
        min-width="100"
        @click="$router.go(-1)"
        >{{ $t("go_back") }}</v-btn
      >
    </v-card-actions>
  </v-form>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // for snow theme
import "quill/dist/quill.bubble.css"; // for bubble theme
import { quillEditor } from "vue-quill-editor";

export default {
  name: "ThesisBasic",

  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    loading: false,
    pipeline_index_options: [],
    thesis: {
      IntroVideo: null,
      InvestmentConditionsIntro: null,
      InvestmentConditionsText: null,
      Overview: null,
    },
    banner: null,
  }),

  components: {
    quillEditor,
  },

  computed: {
    email_valid() {
      return (v) =>
        !v ||
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
          v
        ) ||
        this.$t("format_invalid");
    },
    required() {
      return (value) => !!value || this.$t("required");
    },
  },
  props: {
    Thesis: Object,
  },

  async created() {
    this.thesis = JSON.parse(JSON.stringify(this.Thesis));
    this.banner = JSON.parse(this.thesis.Banner);
    await this.get_pipeline_index_options();
    this.initialize_translatable_field("IntroVideo");
    this.initialize_translatable_field("InvestmentConditionsIntro");
    this.initialize_translatable_field("InvestmentConditionsText");
    this.initialize_translatable_field("Overview");
  },

  methods: {
    async get_pipeline_index_options() {
      await this.apiService
        .getRequest("thesis/pipeline-index-options")
        .then((resp) => {
          this.pipeline_index_options = resp.content.options;
          if (!this.thesis.PipelineIndex) {
            this.thesis.PipelineIndex =
              this.pipeline_index_options[
                this.pipeline_index_options.length - 1
              ];
          }
        })
        .catch((error) => {});
    },
    initialize_translatable_field(field) {
      if (this.thesis[field] != null) {
        if (this.gs.isJson(this.thesis[field])) {
          this.thesis[field] = JSON.parse(this.thesis[field]);
        } else {
          var text = this.thesis[field];
          this.thesis[field] = {};
          this.thesis[field][this.$i18n.locale] = text;
        }
      } else {
        this.thesis[field] = {};
        this.thesis[field][this.$i18n.locale] = "";
      }
    },
    setImageBanner(event) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        if (this.thesis.Base64Banner == null) {
          this.thesis.Base64Banner = {};
        }
        this.thesis.Base64Banner[this.$i18n.locale] = reader.result;
      };
    },
    setImageLogo(event) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        this.thesis.Base64Logo = reader.result;
      };
    },
    checkForm: async function () {
      var result = this.$refs.form.validate();
      if (result) {
        await this.submit();
      } else {
        this.loading = false;
      }
    },
    submit: async function () {
      try {
        if (this.loading) {
          return;
        }
        this.loading = true;
        var obj = JSON.parse(JSON.stringify(this.thesis));

        if (obj.IntroVideo) {
          obj.IntroVideo = JSON.stringify(obj.IntroVideo);
        }

        if (obj.InvestmentConditionsIntro) {
          obj.InvestmentConditionsIntro = JSON.stringify(
            obj.InvestmentConditionsIntro
          );
        }

        if (obj.InvestmentConditionsText) {
          obj.InvestmentConditionsText = JSON.stringify(
            obj.InvestmentConditionsText
          );
        }

        if (obj.Overview) {
          obj.Overview = JSON.stringify(obj.Overview);
        }

        if (obj.Base64Banner) {
          obj.Base64Banner = JSON.stringify(obj.Base64Banner);
        }

        if (this.thesis.Id) {
          await this.apiService
            .putRequest("thesis/edit", obj)
            .then((result) => {
              this.$emit("saved", this.thesis);
              this.$toast.success(this.$t("saved"));
            })
            .catch((error) => {
              this.$toast.error(this.$t(error.body.message));
            });
        } else {
          await this.apiService
            .postRequest("thesis/add", obj)
            .then((result) => {
              this.$toast.success(this.$t("saved"));
              this.$router.go(-1);
            })
            .catch((error) => {
              this.$toast.error(this.$t(error.body.message));
            });
        }
        this.loading = false;
      } catch (error) {
        this.$toast.error(this.$t(error));
      }
    },
  },
};
</script>

<style scoped>
.introduction-text-label,
.condition-text-label {
  font-family: "Source Sans Pro";
  display: block;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 4px;
}

.btn-choose-img-title {
  display: block;
}
</style>
