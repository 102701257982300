<template>
  <v-card class="dxa_modal">
    <v-card-title>
      <h4 class="dxa_modal_title h4">{{ $t("manage_file") }}</h4>
    </v-card-title>
    <v-card-text>
      <v-form @submit.stop.prevent="submit()" ref="form" class="mt-5">
        <v-select
          :items="index_options"
          :label="$t('index')"
          v-model="file.Index"
          outlined
        ></v-select>
        <input
          v-if="!file.Id"
          @change="files_changed"
          type="file"
          id="file"
          ref="file"
          prefix
          label="File input"
        />
        <v-card-actions class="pl-0 dxa_modal_actions">
          <v-btn
            class="dxa_modal_btnSuccess"
            color="primary"
            min-width="100"
            :loading="loading"
            type="submit"
            >{{ $t("save") }}</v-btn
          >
          <v-btn
            class="dxa_modal_btnError"
            color="red"
            min-width="100"
            @click="$emit('close')"
            >{{ $t("cancel") }}</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
export default {
  name: "ManageThesisFiles",

  components: {},

  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    loading: false,
    file: {},
    index_options: [],
  }),

  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
  },
  props: {
    File: Object,
    FileCount: Number,
  },

  async created() {
    this.file = JSON.parse(JSON.stringify(this.File));
    this.index_options = Array.from(
      {
        length: this.File.Id ? this.FileCount : this.FileCount + 1,
      },
      (_, i) => i + 1
    );
  },

  methods: {
    files_changed(event) {
      var file = event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.file.FileName = file.name.split(".")[0];
        this.file.FileExtension = file.name.split(".")[1];
        this.file.Base64File = reader.result;
      };
    },
    submit: async function () {
      if (this.loading) {
        return;
      }
      this.loading = true;

      if (this.$i18n.locale === "pt") {
        this.file.Language = 0;
      } else if (this.$i18n.locale === "en") {
        this.file.Language = 1;
      } else {
        this.file.Language = 2;
      }

      if (this.file.Id) {
        await this.apiService
          .putRequest(`thesisfile/edit`, this.file)
          .then((result) => {
            this.$emit("save");
          })
          .catch((error) => {
            this.$toast.error(error.body.message);
          });
      } else {
        await this.apiService
          .postRequest(`thesisfile/add`, this.file)
          .then((result) => {
            this.$emit("save");
          })
          .catch((error) => {
            this.$toast.error(error.body.message);
          });
      }
      this.loading = false;
    },
  },
};
</script>
