<template>
  <div v-if="!loading">
    <div class="text-center mt-8">
      <v-btn
        color="primary"
        rounded
        @click="add_file_dialog()"
        fab
        top
        right
        absolute
      >
        <v-icon large>mdi-plus</v-icon>
      </v-btn>
    </div>
    <v-simple-table>
      <thead>
        <tr>
          <th>{{ $t("name") }}</th>
          <th class="text-center">
            {{ $t("download") }}
          </th>
          <th class="text-center">{{ $t("edit") }}</th>
          <th class="text-center">
            {{ $t("remove") }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="(file, i) in files.filter(
            (x) => gs.getLanguage(x.Language) === $i18n.locale
          )"
          :key="i"
        >
          <td>{{ file.FileName }}</td>
          <td class="text-center">
            <v-btn
              class="px-2 ml-1 btnDownloadFile"
              icon
              color="primary"
              @click="download_file(file)"
              min-width="0"
              small
              :loading="fileDownloading == file"
            >
              <v-icon small>mdi-download</v-icon>
            </v-btn>
          </td>
          <td class="text-center">
            <v-btn
              class="px-2 ml-1 secondary"
              @click="edit_file_dialog(file)"
              min-width="0"
              small
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </td>
          <td class="text-center">
            <v-btn
              class="px-2 ml-1"
              @click="open_delete_dialog(file)"
              color="red"
              min-width="0"
              small
            >
              <v-icon small>mdi-trash-can</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-dialog v-if="dialog" v-model="dialog">
      <ManageFile
        :File="file"
        :FileCount="
          files.filter((x) => gs.getLanguage(x.Language) === $i18n.locale)
            .length
        "
        @close="dialog = false"
        @save="save_file"
      />
    </v-dialog>
    <v-dialog v-if="delete_dialog" v-model="delete_dialog">
      <DeleteConfirmationModal
        :obj="delete_obj"
        :name="$t('this_file')"
        @close="delete_dialog = false"
        @delete="remove_file"
      ></DeleteConfirmationModal>
    </v-dialog>
  </div>
  <v-row v-else justify="center" align="center" class="pa-4">
    <v-progress-circular
      indeterminate
      size="50"
      color="primary"
    ></v-progress-circular>
  </v-row>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import DeleteConfirmationModal from "@/views/pages/DeleteConfirmationModal";
import ManageFile from "./Dialogs/ManageThesisFile.vue";
export default {
  name: "ThesisFiles",

  components: {
    DeleteConfirmationModal,
    ManageFile,
  },

  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    loading: false,
    dialog_loading: false,
    dialog: false,
    delete_dialog: false,
    delete_obj: null,
    thesis_id: null,
    file: null,
    files: [],
    fileDownloading: null,
  }),

  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
  },
  props: {
    BriefingId: Number,
    company_prop: Object,
  },

  async created() {
    this.thesis_id = this.$route.params.thesis_id;
    await this.refresh_files();
  },

  methods: {
    async save_file() {
      await this.refresh_files();
      this.dialog = false;
    },
    async refresh_files() {
      this.loading = true;
      await this.apiService
        .getRequest(`thesisfile/list`, { ThesisId: this.thesis_id })
        .then((resp) => {
          this.files = JSON.parse(resp.message);
        })
        .catch((err) => {
          this.$toast.error(err.body.message);
        });
      this.loading = false;
    },
    async download_file(file) {
      var name = file.FileName;
      if (name.split(".").length == 1) {
        name += ".pdf";
      }
      this.fileDownloading = file;
      await this.apiService
        .getRequest(`thesisfile/download/${file.Id}`)
        .then((resp) => {
          resp =
            "data:application/octet-stream;base64," + resp.content.fileContent;
          const link = document.createElement("a");
          link.href = resp;
          link.setAttribute("download", name);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {});
      this.fileDownloading = null;
    },
    open_delete_dialog: function (file) {
      this.delete_obj = file;
      this.delete_dialog = true;
    },
    remove_file: async function (file) {
      await this.apiService
        .deleteRequest(`thesisfile/${file.Id}`)
        .then((result) => {
          this.refresh_files();
        })
        .catch((error) => {
          this.$toast.error(err.body.message);
        });
      this.delete_dialog = false;
    },
    edit_file_dialog: function (file) {
      this.file = file;
      this.dialog = true;
    },
    add_file_dialog: function () {
      this.file = {
        ThesisId: this.thesis_id,
      };
      this.dialog = true;
    },
  },
};
</script>
