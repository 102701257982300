import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_c('h4',{staticClass:"dxa_modal_title h4"},[_vm._v(_vm._s(_vm.$t("manage_highlights")))])]),_c(VCardText,[_c(VForm,{ref:"form",staticClass:"mt-5",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.add_highlight()}}},[_c(VSelect,{attrs:{"items":_vm.index_options,"label":_vm.$t('index'),"outlined":""},model:{value:(_vm.highlight.Index),callback:function ($$v) {_vm.$set(_vm.highlight, "Index", $$v)},expression:"highlight.Index"}}),_c(VRow,[_c(VCol,{attrs:{"cols":"1"}},[_c(VSelect,{attrs:{"items":_vm.AvailableLangs,"label":_vm.$t('language'),"rules":[_vm.required],"outlined":""},model:{value:(_vm.selectedLang),callback:function ($$v) {_vm.selectedLang=$$v},expression:"selectedLang"}})],1),_c(VCol,{attrs:{"cols":"11"}},[_c(VTextField,{attrs:{"label":_vm.$t('text'),"rules":[_vm.required]},model:{value:(_vm.highlight.Text[_vm.selectedLang]),callback:function ($$v) {_vm.$set(_vm.highlight.Text, _vm.selectedLang, $$v)},expression:"highlight.Text[selectedLang]"}})],1)],1),_c(VCardActions,[_c(VBtn,{staticClass:"dxa_modal_btnSuccess",attrs:{"color":"primary","min-width":"100","loading":_vm.loading,"type":"submit"}},[_vm._v(_vm._s(_vm.$t("save")))]),_c(VBtn,{staticClass:"dxa_modal_btnError",attrs:{"color":"red","min-width":"100"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(_vm._s(_vm.$t("cancel")))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }