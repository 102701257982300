<template>
  <div>
    <div class="text-center mt-8">
      <v-btn
        color="primary"
        rounded
        @click="add_highlight_dialog()"
        fab
        top
        right
        absolute
      >
        <v-icon large>mdi-plus</v-icon>
      </v-btn>
    </div>
    <v-simple-table>
      <thead>
        <tr>
          <th>{{ $t("text") }}</th>
          <th class="text-center">{{ $t("edit") }}</th>
          <th class="text-center">{{ $t("remove") }}</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(highlight, index) in highlights" :key="index">
          <td>
            <div v-if="gs.isJson(highlight.Text)">
              <p
                class="mb-1"
                v-for="(k, i) in Object.keys(JSON.parse(highlight.Text))"
                :key="i"
              >
                <b style="font-weight: bold">{{ `${k.toUpperCase()}: ` }}</b
                >{{ `${JSON.parse(highlight.Text)[k]}` }}
              </p>
            </div>
            <span v-else>{{ highlight.Text }}</span>
          </td>
          <td class="text-center">
            <v-btn
              class="px-2 ml-1 secondary"
              @click="edit_highlight_dialog(highlight)"
              min-width="0"
              small
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </td>
          <td class="text-center">
            <v-btn
              class="px-2 ml-1"
              @click="open_delete_dialog(highlight)"
              color="red"
              min-width="0"
              small
            >
              <v-icon small>mdi-trash-can</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-dialog v-if="dialog" v-model="dialog">
      <ManageHighlight
        :AvailableLangs="availableLangs"
        :Highlight="highlight"
        :HighlightCount="highlights.length"
        @close="dialog = false"
        @save="save_highlight"
      />
    </v-dialog>
    <v-dialog v-if="delete_dialog" v-model="delete_dialog">
      <DeleteConfirmationModal
        :obj="delete_obj"
        :name="$t('this_highlight')"
        @close="delete_dialog = false"
        @delete="remove_highlight"
      ></DeleteConfirmationModal>
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import DeleteConfirmationModal from "@/views/pages/DeleteConfirmationModal";
import ManageHighlight from "./Dialogs/ManageHighlight.vue";
export default {
  name: "ThesisHighlights",

  components: {
    DeleteConfirmationModal,
    ManageHighlight,
  },

  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    loading: false,
    dialog: false,
    delete_dialog: false,
    delete_obj: null,
    highlights: [],
    thesis_id: null,
    availableLangs: [],
    highlight: {
      Text: null,
    },
  }),

  computed: {},
  props: {},

  async created() {
    this.thesis_id = this.$route.params.thesis_id;
    this.availableLangs = Object.keys(this.$i18n.messages);
    await this.refresh_highlights();
  },

  methods: {
    async refresh_highlights() {
      this.loading = true;
      await this.apiService
        .getRequest(`thesishighlight/list/${this.thesis_id}`)
        .then((resp) => {
          this.highlights = JSON.parse(resp.message);
        })
        .catch((err) => {
          this.$toast.error(err.body.message);
        });
      this.loading = false;
    },
    async save_highlight() {
      await this.refresh_highlights();
      this.dialog = false;
    },
    open_delete_dialog: function (highlight) {
      this.delete_obj = highlight;
      this.delete_dialog = true;
    },
    remove_highlight: async function (highlight) {
      await this.apiService
        .deleteRequest(`thesishighlight/${highlight.Id}`)
        .then((result) => {
          this.delete_dialog = false;
          this.refresh_highlights();
        })
        .catch((error) => {
          this.$toast.error(err.body.message);
        });
    },
    add_highlight_dialog: function () {
      this.edit = false;
      this.highlight = {
        ThesisId: this.thesis_id,
        Text: {},
        Index: this.highlights.length + 1,
      };
      for (var i = 0; i < this.availableLangs.length; i++) {
        this.highlight.Text[this.availableLangs[i]] = "";
      }
      this.dialog = true;
    },
    edit_highlight_dialog: function (highlight) {
      this.edit = true;
      this.highlight = highlight;
      if (this.gs.isJson(this.highlight.Text)) {
        this.highlight.Text = JSON.parse(this.highlight.Text);
      } else {
        this.highlight.Text = {
          pt: this.highlight.Text,
        };
      }
      for (var i = 0; i < this.availableLangs.length; i++) {
        if (!this.highlight.Text[this.availableLangs[i]]) {
          this.highlight.Text[this.availableLangs[i]] = "";
        }
      }
      this.dialog = true;
    },
  },
};
</script>
