<template>
  <div v-if="!loading">
    <div class="text-center mt-8">
      <v-btn
        color="primary"
        rounded
        @click="add_media_dialog()"
        fab
        top
        right
        absolute
      >
        <v-icon large>mdi-plus</v-icon>
      </v-btn>
    </div>
    <v-simple-table>
      <thead>
        <tr>
          <th>{{ $t("title") }}</th>
          <th>{{ $t("media_type") }}</th>
          <th class="text-center">{{ $t("edit") }}</th>
          <th class="text-center">{{ $t("remove") }}</th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="(item, index) in media.filter(
            (x) => gs.getLanguage(x.Language) === $i18n.locale
          )"
          :key="index"
        >
          <td>{{ item.Title }}</td>
          <td>{{ media_options[item.Type] }}</td>
          <td class="text-center">
            <v-btn
              class="px-2 ml-1 secondary"
              @click="edit_media_dialog(item)"
              min-width="0"
              small
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </td>
          <td class="text-center">
            <v-btn
              class="px-2 ml-1"
              @click="open_delete_dialog(item)"
              color="red"
              min-width="0"
              small
            >
              <v-icon small>mdi-trash-can</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-dialog v-if="dialog" v-model="dialog">
      <ManageMedia
        :Media="media_obj"
        :MediaCount="
          media.filter(
            (x) =>
              gs.getLanguage(x.Language) === $i18n.locale && x.Type == MediaType
          ).length
        "
        @close="dialog = false"
        @save="save_media"
      />
    </v-dialog>
    <v-dialog v-if="delete_dialog" v-model="delete_dialog">
      <DeleteConfirmationModal
        :obj="delete_obj"
        :name="delete_obj.Title"
        @close="delete_dialog = false"
        @delete="remove_media"
      ></DeleteConfirmationModal>
    </v-dialog>
  </div>
  <v-row v-else justify="center" align="center" class="pa-4">
    <v-progress-circular
      indeterminate
      size="50"
      color="primary"
    ></v-progress-circular>
  </v-row>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import ManageMedia from "./Dialogs/ManageMedia.vue";
import DeleteConfirmationModal from "@/views/pages/DeleteConfirmationModal";
export default {
  name: "ThesisMedia",

  components: {
    DeleteConfirmationModal,
    ManageMedia,
  },

  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    loading: false,
    dialog: false,
    delete_dialog: false,
    delete_obj: null,
    thesis_id: null,
    media: [],
    media_obj: {
      Title: null,
      Type: 0,
      VideoId: null,
      Plataform: 0,
    },
  }),

  computed: {
    media_options() {
      return [this.$t("dxa_vision"), this.$t("market_vision")];
    },
  },
  props: {
    MediaType: Number,
  },

  async created() {
    this.thesis_id = this.$route.params.thesis_id;
    await this.get_media();
  },

  methods: {
    async save_media() {
      this.dialog = false;
      await this.get_media();
    },
    async get_media() {
      this.loading = true;
      await this.apiService
        .getRequest(`thesismedia/list`, {
          ThesisId: this.thesis_id,
          MediaType: this.MediaType,
        })
        .then((resp) => {
          this.media = JSON.parse(resp.message);
        })
        .catch((error) => {});
      this.loading = false;
    },
    open_delete_dialog: function (media) {
      this.delete_obj = media;
      this.delete_dialog = true;
    },
    remove_media: async function (media) {
      await this.apiService
        .deleteRequest(`thesismedia/${media.Id}`)
        .then((result) => {
          this.get_media();
        })
        .catch((error) => {
          this.$toast.error(err.body.message);
        });
      this.delete_dialog = false;
    },
    add_media_dialog: function () {
      let langId = 0;
      if (this.$i18n.locale === "pt") {
        langId = 0;
      } else if (this.$i18n.locale === "en") {
        langId = 1;
      } else {
        langId = 2;
      }

      this.edit = false;
      this.media_obj = {
        Type: this.MediaType,
        Title: null,
        VideoId: null,
        Plataform: 0,
        Language: langId,
        ThesisId: this.thesis_id,
        Index:
          this.media.filter(
            (x) => this.gs.getLanguage(x.Language) === this.$i18n.locale
          ).length + 1,
      };
      this.dialog = true;
    },
    edit_media_dialog: function (obj) {
      this.media_obj = obj;
      this.dialog = true;
    },
  },
};
</script>
