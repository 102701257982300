import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('div',[_c('div',{staticClass:"text-center mt-8"},[_c(VBtn,{attrs:{"color":"primary","rounded":"","fab":"","top":"","right":"","absolute":""},on:{"click":function($event){return _vm.add_media_dialog()}}},[_c(VIcon,{attrs:{"large":""}},[_vm._v("mdi-plus")])],1)],1),_c(VSimpleTable,[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("title")))]),_c('th',[_vm._v(_vm._s(_vm.$t("media_type")))]),_c('th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t("edit")))]),_c('th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t("remove")))])])]),_c('tbody',_vm._l((_vm.media.filter(
          function (x) { return _vm.gs.getLanguage(x.Language) === _vm.$i18n.locale; }
        )),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(item.Title))]),_c('td',[_vm._v(_vm._s(_vm.media_options[item.Type]))]),_c('td',{staticClass:"text-center"},[_c(VBtn,{staticClass:"px-2 ml-1 secondary",attrs:{"min-width":"0","small":""},on:{"click":function($event){return _vm.edit_media_dialog(item)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)],1),_c('td',{staticClass:"text-center"},[_c(VBtn,{staticClass:"px-2 ml-1",attrs:{"color":"red","min-width":"0","small":""},on:{"click":function($event){return _vm.open_delete_dialog(item)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-trash-can")])],1)],1)])}),0)]),(_vm.dialog)?_c(VDialog,{model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ManageMedia',{attrs:{"Media":_vm.media_obj,"MediaCount":_vm.media.filter(
          function (x) { return _vm.gs.getLanguage(x.Language) === _vm.$i18n.locale && x.Type == _vm.MediaType; }
        ).length},on:{"close":function($event){_vm.dialog = false},"save":_vm.save_media}})],1):_vm._e(),(_vm.delete_dialog)?_c(VDialog,{model:{value:(_vm.delete_dialog),callback:function ($$v) {_vm.delete_dialog=$$v},expression:"delete_dialog"}},[_c('DeleteConfirmationModal',{attrs:{"obj":_vm.delete_obj,"name":_vm.delete_obj.Title},on:{"close":function($event){_vm.delete_dialog = false},"delete":_vm.remove_media}})],1):_vm._e()],1):_c(VRow,{staticClass:"pa-4",attrs:{"justify":"center","align":"center"}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"50","color":"primary"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }