<template>
  <v-container>
    <base-material-card
      color="primary"
      icon="mdi-office-building"
      :title="$t('manage_thesis')"
      class="px-5 py-3"
    >
      <v-row v-if="loading" justify="center" align="center" class="pa-4">
        <v-progress-circular indeterminate size="30" color="primary">
        </v-progress-circular>
      </v-row>
      <div v-else>
        <v-tabs v-if="thesis.Id" v-model="tab">
          <v-tab>{{ $t("basic") }}</v-tab>
          <v-tab>{{ $t("highlights") }}</v-tab>
          <v-tab>{{ $t("files") }}</v-tab>
          <v-tab>{{ $t("dxa_vision") }}</v-tab>
          <v-tab>{{ $t("market_vision") }}</v-tab>
        </v-tabs>

        <Basic v-show="tab == 0" :Thesis="thesis" />
        <Highlights v-if="thesis.Id" v-show="tab == 1" />
        <Files v-if="thesis.Id" v-show="tab == 2" />
        <MediaDXAVision v-if="thesis.Id" :MediaType="0" v-show="tab == 3" />
        <MediaMarketVision v-if="thesis.Id" :MediaType="1" v-show="tab == 4" />
      </div>
    </base-material-card>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
import Basic from "./tabs/Basic";
import Highlights from "./tabs/Highlights";
import Files from "./tabs/Files";
import MediaDXAVision from "./tabs/Media";
import MediaMarketVision from "./tabs/Media";

export default {
  name: "ManageThesis",

  components: {
    Basic,
    Highlights,
    Files,
    MediaDXAVision,
    MediaMarketVision,
  },

  data: () => ({
    apiService: new ApiService(),
    loading: false,
    thesis: {
      IntroVideo: null,
      InvestmentConditionsIntro: null,
      InvestmentConditionsText: null,
      Overview: null,
    },
    tab: 0,
  }),

  computed: {},

  async created() {
    await this.get_thesis();
  },

  methods: {
    async get_thesis() {
      var thesis_id = this.$route.params.thesis_id;
      if (!thesis_id) {
        return;
      }
      this.loading = true;
      await this.apiService
        .getRequest(`thesis/${thesis_id}`)
        .then((resp) => {
          this.thesis = JSON.parse(resp.message);
        })
        .catch((error) => {});
      this.loading = false;
    },
  },
};
</script>
