<template>
  <v-card>
    <v-card-title>
      <h4 class="dxa_modal_title h4">{{ $t("manage_media") }}</h4>
    </v-card-title>
    <v-card-text>
      <v-form @submit.stop.prevent="check_form()" ref="form" class="mt-5">
        <div class="mx-2">
          <v-select
            :items="index_options"
            :label="$t('index')"
            v-model="media.Index"
            outlined
          ></v-select>
          <v-text-field
            v-model="media.Title"
            :rules="[required]"
            :label="$t('title')"
          />

          <v-text-field
            v-model="media.VideoId"
            :rules="[required]"
            :label="$t('video_id')"
          />

          <div class="mt-2">
            <span>{{ $t("media_type") }}</span>
            <v-radio-group center v-model="media.Type" row disabled>
              <v-radio
                v-for="(type, i) in media_options"
                :key="i"
                :label="type"
                :value="i"
              ></v-radio>
            </v-radio-group>
          </div>

          <div class="mt-2">
            <span>{{ $t("media_plataform") }}</span>
            <v-radio-group center v-model="media.Plataform" row>
              <v-radio
                :label="$t('vimeo')"
                :value="videoPlatformEnum.VIMEO"
              ></v-radio>
              <v-radio
                :label="$t('youtube')"
                :value="videoPlatformEnum.YOUTUBE"
              ></v-radio>
            </v-radio-group>
          </div>
        </div>
        <v-card-actions>
          <v-btn
            class="dxa_modal_btnSuccess"
            color="primary"
            :disabled="!media.Title || !media.VideoId"
            min-width="100"
            :loading="loading"
            type="submit"
            >{{ $t("save") }}</v-btn
          >
          <v-btn
            class="dxa_modal_btnError"
            color="red"
            min-width="100"
            @click="$emit('close')"
            >{{ $t("cancel") }}</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import { videoPlatformEnum } from "@/shared/enums/VideoPlatformEnum";
export default {
  name: "ManageThesisMedia",

  components: {},

  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    videoPlatformEnum,
    loading: false,
    media: {},
    index_options: [],
  }),

  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
    media_options() {
      return [this.$t("dxa_vision"), this.$t("market_vision")];
    },
  },
  props: {
    Media: Object,
    MediaCount: Number,
  },

  async created() {
    this.media = JSON.parse(JSON.stringify(this.Media));
    this.index_options = Array.from(
      {
        length: this.media.Id ? this.MediaCount : this.MediaCount + 1,
      },
      (_, i) => i + 1
    );
  },

  methods: {
    check_form: async function () {
      if (this.loading) {
        return;
      }
      this.loading = true;
      var result = this.$refs.form.validate();
      if (result) {
        await this.submit();
      }
      this.loading = false;
    },
    submit: async function () {
      if (this.media.Id) {
        await this.apiService
          .putRequest(`thesismedia/edit`, this.media)
          .then((result) => {
            this.$emit("save");
          })
          .catch((error) => {
            this.$toast.error(error.body.message);
          });
      } else {
        await this.apiService
          .postRequest(`thesismedia/add`, this.media)
          .then((result) => {
            this.$emit("save");
          })
          .catch((error) => {
            this.$toast.error(error.body.message);
          });
      }
    },
  },
};
</script>
